
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

interface Subject {
  _id: string;
  name: string;
}

interface Course {
  icon: {
    file: string;
    color: string;
  };
  _id: string;
  title: string;
  createdAt: string;
  state: {
    text: string;
    color: string;
  };
  module: string;
  subject: Subject;
  status: string;
  reviewDate: string;
  reviewNote: string;
}

export default defineComponent({
  name: "CoursesWidget",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const courses: Course[] = [];
    const filteredCourses = ref<Course[]>([]);

    const subjects = ref<Subject[]>([]);
    const selectedSubject = ref<string>("");
    const search = ref<string>("");
    const loading = ref(true);
    const validateStat = ref<number>(0);
    setCurrentPageBreadcrumbs(t("course.course"), ["Classe"]);

    ApiService.get(`/lms/course/list/${route.params.id}`)
      .then(({ data }) => {
        data.forEach((course) => {
          courses.push({
            icon: {
              file: "media/icons/duotune/files/fil003.svg",
              color: "success",
            },
            _id: course._id,
            title: course.title,
            createdAt: course.createdAt,
            module: course.module,
            state: {
              text: course.deadline
                ? moment(course.deadline).format("MMMM Do YYYY, H:mm")
                : "-",
              color: "light-warning",
            },
            subject: course.subject,
            status: course.status,
            reviewDate: course.reviewDate,
            reviewNote: course.reviewNote,
          });

          if (!subjects.value.find((s: Subject) => s._id == course.subject._id))
            subjects.value.push(course.subject);
        });
        filteredCourses.value = courses;
      })
      .catch((e) => console.log(e))
      .finally(() => {
        loading.value = false;
      });

    const filterCourses = () => {
      currentPage.value = 1;
      filteredCourses.value = courses.filter(
        (course) =>
          course.title.toLowerCase().includes(search.value.toLowerCase()) &&
          (course.subject._id == selectedSubject.value ||
            selectedSubject.value == "") &&
          ((course.status === "validated" && validateStat.value == 1) ||
            (course.status === "notValidated" && validateStat.value == 2) ||
            (course.status === "rejected" && validateStat.value == 3) ||
            validateStat.value == 0)
      );
    };

    const perPage = 7;

    const currentPage = ref<number>(1);

    const totalPages = computed(() =>
      Math.ceil(filteredCourses.value.length / perPage)
    );

    const pageRecords = computed(() => {
      const startIndex = perPage * (currentPage.value - 1) + 1;
      const endIndex = startIndex + perPage - 1;
      return filteredCourses.value.slice(startIndex - 1, endIndex);
    });

    return {
      t,
      courses,
      search,
      moment,
      filterCourses,
      filteredCourses,
      perPage,
      currentPage,
      totalPages,
      pageRecords,
      subjects,
      selectedSubject,
      loading,
      validateStat,
    };
  },
});
